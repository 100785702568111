<script setup lang="ts">
defineProps({
    colors: {
        type: Array as PropType<string[]>,
        default: () => ["#93A4BC", "#5A6273"]
    }
});

const randomId = computed(() => Math.random().toString(36).substr(2, 9));
</script>

<template>
    <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M32 1.19626C32 0.535585 31.4694 0 30.8148 0H24.8889C24.2343 0 23.7037 0.535585 23.7037 1.19626V7.17757C23.7037 7.83825 24.2343 8.37383 24.8889 8.37383H30.8148C31.4694 8.37383 32 7.83825 32 7.17757V1.19626Z"
            :fill="'url(#paint0_linear_308_512' + randomId + ')'"
        />
        <path
            d="M1.18519 2.09346C1.18519 1.43278 1.71581 0.897196 2.37037 0.897196H5.92593C6.58049 0.897196 7.11111 1.43278 7.11111 2.09346V5.68224C7.11111 6.34292 6.58049 6.8785 5.92593 6.8785H2.37037C1.71581 6.8785 1.18519 6.34292 1.18519 5.68224V2.09346Z"
            :fill="'url(#paint1_linear_308_512' + randomId + ')'"
        />
        <path
            d="M13.037 2.09346C13.037 1.43278 13.5677 0.897196 14.2222 0.897196H17.7778C18.4323 0.897196 18.963 1.43278 18.963 2.09346V5.68224C18.963 6.34292 18.4323 6.8785 17.7778 6.8785H14.2222C13.5677 6.8785 13.037 6.34292 13.037 5.68224V2.09346Z"
            :fill="'url(#paint2_linear_308_512' + randomId + ')'"
        />
        <path
            d="M0 12.8598C0 12.1991 0.530625 11.6636 1.18519 11.6636H7.11111C7.76567 11.6636 8.2963 12.1991 8.2963 12.8598V18.8411C8.2963 19.5018 7.76567 20.0374 7.11111 20.0374H1.18519C0.530626 20.0374 0 19.5018 0 18.8411V12.8598Z"
            :fill="'url(#paint3_linear_308_512' + randomId + ')'"
        />
        <path
            d="M18.963 14.0561C18.963 13.3954 18.4323 12.8598 17.7778 12.8598H14.2222C13.5677 12.8598 13.037 13.3954 13.037 14.0561V17.6449C13.037 18.3055 13.5677 18.8411 14.2222 18.8411H17.7778C18.4323 18.8411 18.963 18.3055 18.963 17.6449V14.0561Z"
            :fill="'url(#paint4_linear_308_512' + randomId + ')'"
        />
        <path
            d="M24.8889 14.0561C24.8889 13.3954 25.4195 12.8598 26.0741 12.8598H29.6296C30.2842 12.8598 30.8148 13.3954 30.8148 14.0561V17.6449C30.8148 18.3055 30.2842 18.8411 29.6296 18.8411H26.0741C25.4195 18.8411 24.8889 18.3055 24.8889 17.6449V14.0561Z"
            :fill="'url(#paint5_linear_308_512' + randomId + ')'"
        />
        <path
            d="M7.11111 26.0187C7.11111 25.358 6.58049 24.8224 5.92593 24.8224H2.37037C1.71581 24.8224 1.18519 25.358 1.18519 26.0187V29.6075C1.18519 30.2682 1.71581 30.8037 2.37037 30.8037H5.92593C6.58049 30.8037 7.11111 30.2682 7.11111 29.6075V26.0187Z"
            :fill="'url(#paint6_linear_308_512' + randomId + ')'"
        />
        <path
            d="M11.8519 24.8224C11.8519 24.1618 12.3825 23.6262 13.037 23.6262H18.963C19.6175 23.6262 20.1481 24.1618 20.1481 24.8224V30.8037C20.1481 31.4644 19.6175 32 18.963 32H13.037C12.3825 32 11.8519 31.4644 11.8519 30.8037V24.8224Z"
            :fill="'url(#paint7_linear_308_512' + randomId + ')'"
        />
        <path
            d="M30.8148 26.0187C30.8148 25.358 30.2842 24.8224 29.6296 24.8224H26.0741C25.4195 24.8224 24.8889 25.358 24.8889 26.0187V29.6075C24.8889 30.2682 25.4195 30.8037 26.0741 30.8037H29.6296C30.2842 30.8037 30.8148 30.2682 30.8148 29.6075V26.0187Z"
            :fill="'url(#paint8_linear_308_512' + randomId + ')'"
        />
        <defs>
            <linearGradient
                :id="'paint0_linear_308_512' + randomId"
                x1="16"
                y1="0"
                x2="16"
                y2="32"
                gradientUnits="userSpaceOnUse"
            >
                <stop :stop-color="colors[0]" />
                <stop offset="1" :stop-color="colors[1]" />
            </linearGradient>
            <linearGradient
                :id="'paint1_linear_308_512' + randomId"
                x1="16"
                y1="0"
                x2="16"
                y2="32"
                gradientUnits="userSpaceOnUse"
            >
                <stop :stop-color="colors[0]" />
                <stop offset="1" :stop-color="colors[1]" />
            </linearGradient>
            <linearGradient
                :id="'paint2_linear_308_512' + randomId"
                x1="16"
                y1="0"
                x2="16"
                y2="32"
                gradientUnits="userSpaceOnUse"
            >
                <stop :stop-color="colors[0]" />
                <stop offset="1" :stop-color="colors[1]" />
            </linearGradient>
            <linearGradient
                :id="'paint3_linear_308_512' + randomId"
                x1="16"
                y1="0"
                x2="16"
                y2="32"
                gradientUnits="userSpaceOnUse"
            >
                <stop :stop-color="colors[0]" />
                <stop offset="1" :stop-color="colors[1]" />
            </linearGradient>
            <linearGradient
                :id="'paint4_linear_308_512' + randomId"
                x1="16"
                y1="0"
                x2="16"
                y2="32"
                gradientUnits="userSpaceOnUse"
            >
                <stop :stop-color="colors[0]" />
                <stop offset="1" :stop-color="colors[1]" />
            </linearGradient>
            <linearGradient
                :id="'paint5_linear_308_512' + randomId"
                x1="16"
                y1="0"
                x2="16"
                y2="32"
                gradientUnits="userSpaceOnUse"
            >
                <stop :stop-color="colors[0]" />
                <stop offset="1" :stop-color="colors[1]" />
            </linearGradient>
            <linearGradient
                :id="'paint6_linear_308_512' + randomId"
                x1="16"
                y1="0"
                x2="16"
                y2="32"
                gradientUnits="userSpaceOnUse"
            >
                <stop :stop-color="colors[0]" />
                <stop offset="1" :stop-color="colors[1]" />
            </linearGradient>
            <linearGradient
                :id="'paint7_linear_308_512' + randomId"
                x1="16"
                y1="0"
                x2="16"
                y2="32"
                gradientUnits="userSpaceOnUse"
            >
                <stop :stop-color="colors[0]" />
                <stop offset="1" :stop-color="colors[1]" />
            </linearGradient>
            <linearGradient
                :id="'paint8_linear_308_512' + randomId"
                x1="16"
                y1="0"
                x2="16"
                y2="32"
                gradientUnits="userSpaceOnUse"
            >
                <stop :stop-color="colors[0]" />
                <stop offset="1" :stop-color="colors[1]" />
            </linearGradient>
        </defs>
    </svg>
</template>
